import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { Tooltip, Box } from "@mui/material";
import Header from "../layout/Header";
import CommissionTreeAdvancedSearch from "./CommissionTreeAdvancedSearch";
import FullPageGridContainer from "../layout/FullPageGridContainer";
import useFetch from "../../hooks/useFetch";
import ErrorMessage from "../pages/ErrorMessage";
import InternalLink from "../routing/InternalLink";
import { AuthContext } from "../../services/Authentication";
import usePermissions from "../../hooks/usePermissions";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const usdPrice = {
  type: "number",
  valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
};

const CustomToolbar = () => (
  <GridToolbarContainer className="flex justify-between">
    <Box>
      <GridToolbarColumnsButton />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </Box>
    <CommissionTreeAdvancedSearch />
  </GridToolbarContainer>
);

const CustomFooter = (props) => (
  <Box
    sx={{ padding: "16px", display: "flex", flexDirection: "row-reverse" }}
  >
    {props.totalCommissionString}
  </Box>
);

const CommissionTreeReport = () => {
  const [sales, setSales] = useState([]);
  const [salesRepSummary, setSalesRepSummary] = useState([]);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const { state: authState } = useContext(AuthContext);
  const permissions = usePermissions();
  const [totalCommissionString, setTotalCommissionString] = useState("");

  const netsuiteColumns = [
    "closedate",
    "trandate",
    "tranid",
    "internalid",
    "entity",
    "quantity",
    "item",
    "memo",
    "partner",
    "salesrep",
    "amount",
    "otherrefnum",
  ];

  const columns = [
    { field: "id", headerName: "id", hide: true },
    { field: "closedate", headerName: "Date Closed", minWidth: 100 },
    {
      field: "document_number",
      headerName: "Document Number",
      minWidth: 100,
      renderCell: (params) =>
        /^INV[0-9]+$/i.test(params?.row?.document_number) ? (
          <InternalLink to={`/invoices/${params?.row?.internalid}`}>
            {params?.row?.document_number}
          </InternalLink>
        ) : (
          params?.row?.document_number
        ),
    },
    {
      renderCell,
      field: "otherrefnum",
      headerName: "LE-#",
      description: "",
      minWidth: 100,
      flex: 1,
    },
    {
      renderCell,
      field: "customer",
      headerName: "Customer",
      description: "",
      minWidth: 50,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      description: "Amount invoice line",
      minWidth: 100,
      ...usdPrice,
    },
  ];

  if (permissions.canViewAllISRCommission) {
    columns.push({
      renderCell,
      field: "isr_supervisor",
      headerName: "ISR Supervisor",
      minWidth: 175,
    });

    netsuiteColumns.push("salesrep.supervisor");
  }

  columns.push({
    renderCell,
    field: "isr",
    headerName: "ISR",
    description: "Inside sales rep in Netsuite",
    minWidth: 175,
  });

  const netsuiteFilters = [];
  if (params.salesrep)
    netsuiteFilters.push("AND", ["salesrep", "is", params.salesrep]);
  if (params.supervisor)
    netsuiteFilters.push("AND", [
      "salesrep.supervisor",
      "is",
      params.supervisor,
    ]);
  if (params.partner)
    netsuiteFilters.push("AND", ["partner", "is", params.partner]);
  if (params.customer)
    netsuiteFilters.push("AND", [
      "customer.altname",
      "contains",
      params.customer,
    ]);

  const invoiceFilters = [...netsuiteFilters];
  const creditMemoFilters = [...netsuiteFilters];

  if (params.startdate && params.enddate) {
    invoiceFilters.push("AND", [
      "formuladate: CASE WHEN {type}='Invoice' THEN {closedate} ELSE {trandate} END",
      "within",
      params.startdate,
      params.enddate,
    ]);
    creditMemoFilters.push("AND", [
      "systemnotes.date",
      "within",
      params.startdate,
      params.enddate,
    ]);
  }

  let url = `${process.env.REACT_APP_BASE_URL}/api/reports/commission/tree/?results=${JSON.stringify(
    netsuiteColumns
  )}`;

  if (netsuiteFilters) {
    url += `&invoiceParams=${JSON.stringify(
      invoiceFilters
    )}&creditMemoParams=${JSON.stringify(creditMemoFilters)}`;
  }

  const { isLoading, error, data: json } = useFetch({ url });

  useEffect(() => {
    if (json) {

      console.log("JSON", json)
      
      const {
        invoices: { result: invoiceResult, data: invoiceData = [] },
        creditMemos: { result: creditMemoResult, data: creditMemoData = [] },
        commissionResults: commissionResults,
      } = json;

      if (invoiceResult === "success" && creditMemoResult === "success") {
        const data = [...invoiceData, ...creditMemoData];
        
        // Log data to diagnose issue
        console.log("Received Data", data);

        const mappedSales = data.map(
          ({
            internalid: [{ value: internalid } = {}] = [],
            entity: [{ text: customer } = {}] = [],
            closedate,
            trandate,
            tranid: document_number,
            memo: description,
            salesrep: [{ text: isr } = {}] = [],
            "salesrep.supervisor": [{ text: isr_supervisor } = {}] = [],
            amount,
            otherrefnum,
          },
          index
          ) => ({
            id: index,
            internalid,
            document_number,
            customer,
            isr,
            isr_supervisor,
            closedate: closedate || trandate,
            amount,
            description,
            otherrefnum,
          })
        );
  
        console.log("Mapped Sales", mappedSales);
  
        setSales(mappedSales);

        let commissionString = "";
        if (permissions.canViewAnyISRCommission) {
          const isrTotalCommission = data
            .reduce((sum, sale) => {
              return sum + (parseFloat(sale["formulacurrency"]) || 0);
            }, 0)
            .toFixed(2);
          commissionString += `Total ISR Commission: $${isrTotalCommission}`;
        }

        const nameToIdMap = {};
        const summary = data.reduce((acc, sale) => {
          // Access the salesrep name and ID
          const repName = sale.salesrep && sale.salesrep[0]?.text ? sale.salesrep[0].text : "Unknown";
          const repId = sale.salesrep && sale.salesrep[0]?.value;

          if (repId) {
            nameToIdMap[repName] = repId; // Map the name to the ID
          }

          if (!acc[repName]) {
            acc[repName] = { totalSales: 0, totalCashSales: 0 };
          }

          // Add to the total sales amount
          acc[repName].totalSales += parseFloat(sale.amount) || 0;

          // Subtract from cash sales if it is a cash sale
          if (sale.isCashSale) {
            acc[repName].totalCashSales -= parseFloat(sale.amount) || 0;
          }

          return acc;
        }, {});

        // Use commissionResults to calculate each sales rep's commission breakdown
        const summaryArray = Object.keys(summary).map((rep, index) => {
          console.log(summary)
          const totalSales = summary[rep].totalSales;
          const repId = nameToIdMap[rep];

          // Find the corresponding commission structure for this rep
          const commissionData = commissionResults.find(c => c.isr === repId) || {
            repCommission: 0,
            teamLeaderOverride: 0,
            callCenterManagerOverride: 0,
          };

          // Calculate commission amounts based on the structure
          const repCommissionAmount = (commissionData.repCommission / 100) * totalSales;
          const teamLeaderOverrideAmount = (commissionData.teamLeaderOverride / 100) * totalSales;
          const callCenterManagerOverrideAmount = (commissionData.callCenterManagerOverride / 100) * totalSales;

          return {
            id: `${rep}-${index}`,
            isr: rep,
            totalSales: totalSales.toFixed(2),
            repCommission: repCommissionAmount.toFixed(2),
            teamLeaderOverrideCommission: teamLeaderOverrideAmount.toFixed(2),
            callCenterManagerOverrideCommission: callCenterManagerOverrideAmount.toFixed(2),
          };
        });

        // Calculate totals row
        const totalsRow = {
          id: "totals",
          isr: "Total",
          totalSales: summaryArray.reduce((sum, row) => sum + parseFloat(row.totalSales || 0), 0).toFixed(2),
          repCommission: summaryArray.reduce((sum, row) => sum + parseFloat(row.repCommission || 0), 0).toFixed(2),
          teamLeaderOverrideCommission: permissions.isAdmin
            ? summaryArray.reduce((sum, row) => sum + parseFloat(row.teamLeaderOverrideCommission || 0), 0).toFixed(2)
            : null,
          callCenterManagerOverrideCommission: permissions.isAdmin
            ? summaryArray.reduce((sum, row) => sum + parseFloat(row.callCenterManagerOverrideCommission || 0), 0).toFixed(2)
            : null,
        };

        console.log("Summary Array", summaryArray);  // Check if summary is generated as expected

        const updatedSummaryArray = [...summaryArray, totalsRow];
        setSalesRepSummary(updatedSummaryArray);
        setTotalCommissionString(commissionString);
      }
    }
  }, [json, authState, permissions.canViewAnyISRCommission]);

  window.document.title = "Commission Report | Suite Sales";

  if (error) return <ErrorMessage error={error} />;

  const summaryColumns = [
    { field: "isr", headerName: "Sales Rep", minWidth: 150 },
    { field: "totalSales", headerName: "Sold", minWidth: 150, ...usdPrice },
    { field: "repCommission", headerName: "Rep Commission", minWidth: 150, ...usdPrice },
    ...(permissions.isAdmin
      ? [
          {
            field: "teamLeaderOverrideCommission",
            headerName: "Team Leader Override Commission",
            minWidth: 250,
            ...usdPrice,
          },
          {
            field: "callCenterManagerOverrideCommission",
            headerName: "Call Center Manager Override Commission",
            minWidth: 300,
            ...usdPrice,
          },
        ]
      : []),
  ];

  return (
    <>
      <Header header="Commission Totals" className="justify-between" />
      <FullPageGridContainer>
        <DataGridPro
          rows={salesRepSummary}
          columns={summaryColumns}
          disableSelectionOnClick={true}
          density="standard"
          disableColumnMenu={true}
          autoHeight
          getRowClassName={(params) => (params.row.id === "totals" ? "totals-row" : "")}
          components={{
            Toolbar: () => <CustomToolbar exportButton={true} />,
          }}
        />
        <Header header="Invoices and Credit Memos" className="justify-between" />
        <DataGridPro
          rows={sales}
          disableSelectionOnClick={true}
          columns={columns}
          loading={isLoading}
          density="standard"
          disableColumnMenu={true}
          // sortModel={[
          //   { field: "isr", sort: "asc" },
          //   { field: "closedate", sort: "asc" },
          // ]}
          // getRowId={(row) => row.internalid}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          componentsProps={{
            footer: { totalCommissionString },
          }}
        />
      </FullPageGridContainer>
    </>
  );
};

export default CommissionTreeReport;
