export const unitedStates = [
  {
    name: "Alabama",
    abbreviation: "AL",
    id: 1,
  },
  {
    name: "Alaska",
    abbreviation: "AK",
    id: 2,
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
    id: 3,
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
    id: 4,
  },
  {
    name: "Armed Forces Americas",
    abbreviation: "AA",
    id: 5,
  },
  {
    name: "Armed Forces Europe",
    abbreviation: "AE",
    id: 6,
  },
  {
    name: "Armed Forces Pacific",
    abbreviation: "AP",
    id: 7,
  },
  {
    name: "California",
    abbreviation: "CA",
    id: 8,
  },
  {
    name: "Colorado",
    abbreviation: "CO",
    id: 9,
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
    id: 10,
  },
  {
    name: "Delaware",
    abbreviation: "DE",
    id: 11,
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
    id: 12,
  },
  {
    name: "Florida",
    abbreviation: "FL",
    id: 13,
  },
  {
    name: "Georgia",
    abbreviation: "GA",
    id: 14,
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
    id: 15,
  },
  {
    name: "Idaho",
    abbreviation: "ID",
    id: 16,
  },
  {
    name: "Illinois",
    abbreviation: "IL",
    id: 17,
  },
  {
    name: "Indiana",
    abbreviation: "IN",
    id: 18,
  },
  {
    name: "Iowa",
    abbreviation: "IA",
    id: 19,
  },
  {
    name: "Kansas",
    abbreviation: "KS",
    id: 20,
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
    id: 21,
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
    id: 22,
  },
  {
    name: "Maine",
    abbreviation: "ME",
    id: 23,
  },
  {
    name: "Maryland",
    abbreviation: "MD",
    id: 24,
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
    id: 25,
  },
  {
    name: "Michigan",
    abbreviation: "MI",
    id: 26,
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
    id: 27,
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
    id: 28,
  },
  {
    name: "Missouri",
    abbreviation: "MO",
    id: 29,
  },
  {
    name: "Montana",
    abbreviation: "MT",
    id: 30,
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
    id: 31,
  },
  {
    name: "Nevada",
    abbreviation: "NV",
    id: 32,
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
    id: 33,
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
    id: 34,
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
    id: 35,
  },
  {
    name: "New York",
    abbreviation: "NY",
    id: 36,
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
    id: 37,
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
    id: 38,
  },
  {
    name: "Ohio",
    abbreviation: "OH",
    id: 39,
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
    id: 40,
  },
  {
    name: "Oregon",
    abbreviation: "OR",
    id: 41,
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
    id: 42,
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
    id: 43,
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
    id: 44,
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
    id: 45,
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
    id: 46,
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
    id: 47,
  },
  {
    name: "Texas",
    abbreviation: "TX",
    id: 48,
  },
  {
    name: "Utah",
    abbreviation: "UT",
    id: 49,
  },
  {
    name: "Vermont",
    abbreviation: "VT",
    id: 50,
  },
  {
    name: "Virginia",
    abbreviation: "VA",
    id: 51,
  },
  {
    name: "Washington",
    abbreviation: "WA",
    id: 52,
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
    id: 53,
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
    id: 54,
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
    id: 55,
  },
];

export const canadianProvinces = [
  {
    name: "Alberta",
    abbreviation: "AB",
    id: 1,
  },
  {
    name: "British Columbia",
    abbreviation: "BC",
    id: 2,
  },
  {
    name: "Manitoba",
    abbreviation: "MB",
    id: 3,
  },
  {
    name: "New Brunswick",
    abbreviation: "NB",
    id: 4,
  },
  {
    name: "Newfoundland",
    abbreviation: "NL",
    id: 5,
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
    id: 6,
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
    id: 7,
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
    id: 8,
  },
  {
    name: "Ontario",
    abbreviation: "ON",
    id: 9,
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
    id: 10,
  },
  {
    name: "Quebec",
    abbreviation: "QC",
    id: 11,
  },
  {
    name: "Saskatchewan",
    abbreviation: "SK",
    id: 12,
  },
  {
    name: "Yukon",
    abbreviation: "YT",
    id: 13,
  },
];
export const countries = [
  { id: 1, name: "United States", abbreviation: "US" },
  { id: 2, name: "Canada", abbreviation: "CA" },
];

export const customerTypes = [
  { name: "Company", id: 1, isperson: "F" },
  { name: "Individual", id: 2, isperson: "T" },
];

export const customerStatuses = [
  { id: 6, name: "Lead - Unqualified" },
  { id: 14, name: "Prospect - Closed Lost" },
  { id: 8, name: "Prospect - In Discussion" },
  { id: 13, name: "Customer - Active" },
];

export const entities = [
  { id: 1, name: "Customers" },
  { id: 2, name: "Sales Orders" },
  { id: 3, name: "Invoices" },
];

export const salesOrderStatuses = [
  { id: 1, name: "Pending Approval", netsuiteId: "A" },
  { id: 2, name: "Pending Fulfillment", netsuiteId: "B" },
  { id: 3, name: "Partially Fulfilled", netsuiteId: "D" },
  { id: 4, name: "Billed", netsuiteId: "G" },
  { id: 5, name: "Closed", netsuiteId: "H" },
];

export const invoiceStatuses = [
  { id: 1, name: "Open", netsuiteId: "A" },
  { id: 2, name: "Paid In Full", netsuiteId: "B" },
];

export const inventoryLocations = [
  { id: 1, name: "American Fork, UT" },
  // { id: 4, name: "Trade Show" },
  // { id: 5, name: "Storefront" },
  // { id: 10, name: "Bryan" },
  // { id: 11, name: "David" },
  // { id: 12, name: "Ken" },
  // { id: 13, name: "Shaun" },
  // { id: 14, name: "Costco Roadshows" },
  // { id: 15, name: "NovEx" },
  // { id: 16, name: "Damaged" },
  // { id: 17, name: "BTX" },
  // { id: 18, name: "Falcon" },
  // { id: 19, name: "767 Auto Mall Dr" },
  { id: 20, name: "Atlanta, GA" },
  // { id: 22, name: "GA Costco Inventory" },
  // { id: 23, name: "UT Costco Inventory" },
];

export const defaultLocations = [
  { id: 1, name: "American Fork, UT" },
  { id: 20, name: "Atlanta, GA" },
];

export const itemCollections = [
  { id: 8, name: "Lion Energy" },
  { id: 1, name: "4Patriots" },
  { id: 7, name: "Costco" },
  { id: 4, name: "Get Smarter" },
  { id: 2, name: "Morningside" },
  { id: 3, name: "Optivida" },
  { id: 6, name: "Patriot Health Alliance" },
];

export const customerClasses = [
  { id: 1, name: "SLRVM - RV and Marine" },
  { id: 14, name: "SLMED - Media" },
  { id: 28, name: "SLRON - Retail Online" },
  { id: 41, name: "MSD2A - Affiliate" },
  { id: 6, name: "SLD2C - Direct to Consumer" },
  { id: 7, name: "SLESR - Energy Storage Residential" },
  { id: 8, name: "GMESC - ESS Commercial" },
  { id: 92, name: "SLGLE - Law Enforcement" },
  { id: 93, name: "SLGMI - Military" },
  { id: 94, name: "SLGDW - Division of Wildlife" },
  { id: 783, name: "SLESD - Energy Storage Direct"},
  { id: 1432, name: "DLEIN - Lion Energy Installation"},
  { id: 1448, name: "SBB2B - Sanctuary B2B"},
];

export const terms = [
  { id: 4, name: "Due on receipt" },
  { id: 19, name: "Net 10" },
  { id: 2, name: "Net 30" },
  { id: 3, name: "Net 60" },
  { id: 29, name: "2% 60, Net 61"},
  { id: 26, name: "EnerBank Financing" },
  { id: 23, name: "5% 10, Net 30" },
  { id: 29, name: "2% 60, Net 61 Days"},
];

export const priceLevels = [
  { id: 1, name: "Base Price" },
  { id: 11, name: "MAP" },
  { id: 21, name: "National Acct (+5)" },
  { id: 14, name: "Drop Ship Dealer" },
  { id: 19, name: "Distributor" },
  { id: 13, name: "Dealer"} // the id might be 12, both 12 and 13 are listed as Dealer
];

export const inventoryLevels = [
  { id: 1, name: "None" },
  { id: 2, name: "Lion Items" },
  { id: 3, name: "All Items" },
];

export const commissionTypes = [
  { id: 1, name: "ISR Commission" },
  { id: 2, name: "OSR Commission" },
  { id: 3, name: "CSR Commission" },
];

export const shippingMethods = [
  {id: 2849, name: "Any" },
  {id: 2851, name: "UPS Ground" },
  {id: 3014, name: "Free Shipping" },
  {id: 3015, name: "UPS 2nd Day Air" },
  {id: 3021, name: "UPS Next Day Air" },
  //{id: 1116, name: "- None -" },
]
