import React, {useContext} from "react";
import LinkCard from "../layout/LinkCard";
import Header from "../layout/Header";
import MainContainer from "../layout/MainContainer";
import usePermissions from "../../hooks/usePermissions";
import { Typography } from "@mui/material";
import { AuthContext } from "../../services/Authentication";

const Dashboard = (props) => {
  const permissions = usePermissions()
  const { state: authState } = useContext(AuthContext);
  // Add to this as we add reports
  const canViewAnyReports = permissions.canViewAnyItems
  const header = "Reports"

  const getCommissionURL = () => {
    let params = {};
      const today = new Date();
      const prevMonth = today.getMonth() - 1;
      const firstDay = 1;
      const lastDay = 0
      const startDate = new Date(today.getFullYear(), prevMonth, firstDay).toLocaleDateString();
      const endDate = new Date(today.getFullYear(), today.getMonth(), lastDay).toLocaleDateString();
      params.startdate = startDate;
      params.enddate = endDate;
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/commission${urlParams}`;
  };

  const getCommissionTreeURL = () => {
    let params = {};
      const today = new Date();
      const prevMonth = today.getMonth() - 1;
      const firstDay = 1;
      const lastDay = 0
      const startDate = new Date(today.getFullYear(), prevMonth, firstDay).toLocaleDateString();
      const endDate = new Date(today.getFullYear(), today.getMonth(), lastDay).toLocaleDateString();
      params.startdate = startDate;
      params.enddate = endDate;
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/commissiontree${urlParams}`;
  };

  const commissionURL = getCommissionURL()
  const commissionTreeURL = getCommissionTreeURL()

  window.document.title = `${header} | Suite Sales`

  return (
    <>
      <Header header={header} />
      <MainContainer className="flex flex-row flex-wrap py-10 justify-center space-x-10">
        {!canViewAnyReports && 
        <Typography>You do not have access to any reports</Typography>
        }
        {permissions.canViewAnyItems && <LinkCard
          url={`/inventory/?location=${authState.user.default_location}`}
          title={"Inventory"}
          titleProps={{ variant: "h5", className: "my-2" }}
          bodyProps={{ variant: "body2", color: "text.secondary" }}
          img={"/img/inventory.jpg"}
          imgAlt={"man counting inventory"}
          body={"Quantities of available and backordered inventory"}
        />}
        {permissions.canViewAnyCommission && <LinkCard
          url={commissionURL}
          title={"Commission"}
          titleProps={{ variant: "h5", className: "my-2" }}
          bodyProps={{ variant: "body2", color: "text.secondary" }}
          img={"/img/commission.jpg"}
          imgAlt={"hand with money"}
          body={"Pending and earned commission"}
        />}
        {permissions.canViewAnyCommission && <LinkCard
          url={commissionTreeURL}
          title={"Commission Tree"}
          titleProps={{ variant: "h5", className: "my-2" }}
          bodyProps={{ variant: "body2", color: "text.secondary" }}
          img={"/img/commission-tree.jpg"}
          imgAlt={"hand with money"}
          body={"Pending and earned commission"}
        />}
      </MainContainer>
    </>
  );
};

export default Dashboard;
